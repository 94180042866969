
.auth-page-wrapper {
  background-image: linear-gradient(180deg, rgb(0 0 0 / 62%), rgb(0 0 0)),
    url(https://img.favpng.com/4/23/8/car-motor-oil-lubricant-engine-png-favpng-sJeD87zSYrdt30yj74mz8DC4A.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-page-wrapper .footer {
  left: 0;
  background-color: transparent;
  color: var(--vz-body-color);
}
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.auth-one-bg-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 380px;
}

@media (max-width: 575.98px) {
  .auth-one-bg-position {
    height: 280px;
  }
}

.auth-one-bg {
  background-image: url(https://member.tirrentglobal.com/assets/images/authContainer/auth-one-bg.jpg);
  background-position: center;
  background-size: cover;
}

.auth-one-bg .bg-overlay {
  background: linear-gradient(45deg, rgb(83 55 39) 40%, rgb(188 102 7) 100%);
  opacity: 0.9;
}

.shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.shape > svg {
  width: 100%;
  height: auto;
  fill: var(--vz-body-bg);
}

.auth-pass-inputgroup input[type="text"] + .btn .ri-eye-fill:before {
  content: "\ecb6";
}

.particles-js-canvas-el {
  position: relative;
}

.signin-other-title {
  position: relative;
}

.signin-other-title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  border-top: 1px dashed var(--vz-border-color);
  top: 10px;
}

.signin-other-title .title {
  display: inline-block;
  position: relative;
  z-index: 9;
  background-color: var(--vz-card-bg-custom);
  padding: 2px 16px;
}

.auth-bg-cover {
  background: linear-gradient(-45deg, #0d6efd 50%, #198754);
}

.auth-bg-cover > .bg-overlay {
  /* background-image: url(../images/cover-pattern.png); */
  background-position: center;
  background-size: cover;
  opacity: 1;
  background-color: transparent;
}

.auth-bg-cover .footer {
  color: rgba(255, 255, 255, 0.5);
}

#password-contain {
  display: none;
}

#password-contain p {
  padding-left: 13px;
}

#password-contain p.valid {
  color: #0ab39c;
}

#password-contain p.valid::before {
  position: relative;
  left: -8px;
  content: "✔";
}

#password-contain p.invalid {
  color: #f06548;
}

#password-contain p.invalid::before {
  position: relative;
  left: -8px;
  content: "✖";
}

.auth-page-wrapper .auth-page-content {
  padding-bottom: 60px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.login-padding {
  padding-bottom: 250px !important;
}

.otp-content {
  height: 100vh;
}
.card-bg {
  background-color: #0e0e0e !important;
}

.full-withradius {
  position: relative;
  background-clip: padding-box;
  border: solid 8px transparent;
  border-radius: 0.8rem;
}
.full-withradius:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -8px;
  border-radius: inherit;
  background: linear-gradient(
    45deg,
    rgba(238, 153, 58, 1) 40%,
    rgba(191, 92, 33, 1) 100%
  );
}
.auth-heading {
  color: rgba(238, 153, 58, 1) !important;
}

.auth-lable {
  color: #fff;
}

.auth-input {
  color: #fff;
  background-color: #0e0e0e;
  background-clip: padding-box;
  border: 1px solid #1D232A;
}

.auth-btn {
  background: linear-gradient(
    45deg,
    rgba(191, 92, 33, 1) 40%,
    rgba(238, 153, 58, 1) 100%
  );
  border: none;
}

.shape > svg {
  fill: #1D232A;
}

.auth-text {
  color: rgba(238, 153, 58, 1);
}
.auth-text:hover {
  color: rgba(238, 153, 58, 1);
}
.auth-input:focus {
  color: #fff;
  background-color: #0e0e0e;
  background-clip: padding-box;
  border: 1px solid #1D232A;
}
.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 25px;
}
.waviy span {
  /* font-family: "Alfa Slab One", cursive; */
  position: relative;
  display: inline-block;

  text-transform: uppercase;
  animation: waviy 2s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

@media (max-width: 500px) {
  .waviy {
    font-size: 19px;
  }
}
@media (max-width: 400px) {
  .waviy {
    font-size: 16px;
  }
}
.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}
.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 1px solid #f99f36;
  margin-left: 8px;
  margin-right: 8px;
  background: #1D232A;
  color: #fff;
  font-size: 20px;
}
.inputStyle:focus {
  color: #fff;
  background-color: #0e0e0e;
  background-clip: padding-box;
  border: 1px solid #f99f36;
}
body ::-webkit-scrollbar {
  background-color: transparent;
}
.wallet-blnc {
  display: flex;
  align-items: center;
  position: absolute;
  right: 5%;
  top: 13%;
}
.icon-blnc {
  margin-top: 7px;
  border: 2px solid #1D232A;
  display: flex;
  background-color: #1D232A;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  border-radius: 10px;
}
.icon-blnc h6,
.icon-blnc i {
  font-size: 16px;
  margin-bottom: 0px;
  color: #fff;
}
.icon-blnc p {
  margin-bottom: 0;
  font-size: 15px !important;
  color: #f99f36;
}
.log-title i {
  background-color: #f99f36;
  padding: 10px;
  float: right;
  border-radius: 50%;
  transform: rotate(45deg);
}
@media (max-width: 767px) {
  .booster-fix-sm {
    order: -1;
    width: 50%;
  }
}
.user-profile tr th {
  font-size: 16px;
}
.user-profile tr td {
  font-size: 16px;
  color: #f99f36;
}
.top-section {
  padding: 20px;
}
.top-section h5 {
  color: #fff;
}
.top-section h5 span {
  color: #f99f36;
}
.security-code,
.security-code:active {
  background-color: #000000 !important;
  color: #fff !important;
  border: none;
}
.color {
  color: #ffff !important;
}
