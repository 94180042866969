.error-item {
    height: 500px;
}
.error-item h1 {
    font-size: 80px;
}
.error-item p {
    font-size: 18px;
}
.error-item a {
    padding: 15px 35px;
    font-size: 16px;
    margin-top: 35px;
}