
#ct-loadding {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-transition: .3s cubic-bezier(.27, .76, .38, .87);
  -khtml-transition: .3s cubic-bezier(.27, .76, .38, .87);
  -moz-transition: .3s cubic-bezier(.27, .76, .38, .87);
  -ms-transition: .3s cubic-bezier(.27, .76, .38, .87);
  -o-transition: .3s cubic-bezier(.27, .76, .38, .87);
  transition: .3s cubic-bezier(.27, .76, .38, .87)
}
.ct-gears {
  width: 350px;
  display: block;
  text-align: center;
  font-weight: 700;
  font-family: Arial;
  color: #fff;
  text-shadow: 1px 1px 2px #5f868a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.ct-gears .big {
  width: 70px;
  height: 70px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-animation: ct-rotate .5s linear infinite;
  -moz-animation: ct-rotate .5s linear infinite;
  -o-animation: ct-rotate .5s linear infinite;
  animation: ct-rotate .5s linear infinite
}
.ct-gears .small {
  width: 50px;
  height: 50px;
  margin-left: -12px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-animation: reverseRotate .3s linear infinite;
  -moz-animation: reverseRotate .3s linear infinite;
  -o-animation: reverseRotate .3s linear infinite;
  animation: reverseRotate .3s linear infinite
}


@keyframes ct-rotate {
  0% {
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0)
  }
  100% {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg)
  }
}
@keyframes reverseRotate {
  0% {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg)
  }
  100% {
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0)
  }
}

